<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="nhân viên" titleIcon="fa fa-users">
                <template slot="actionBefore">
                    <b-tabs class="c-tabs-button mr-2" small pills v-model="filterFormModel.blocked" @input="load">
                        <b-tab :title="`Tất cả (` +  (Number(params.tabCount[0] ? params.tabCount[0] : 0) + Number(params.tabCount[1] ? params.tabCount[1] : 0)) + `)`"></b-tab>
                        <b-tab :title="`Hoạt động (` +  (params.tabCount[0] ? params.tabCount[0] : 0) + `)`"></b-tab>
                        <b-tab :title="`Tạm khoá (` +  (params.tabCount[1] ? params.tabCount[1] : 0) + `)`"></b-tab>
                    </b-tabs>
                </template>
                <template slot="action">
                    <g-button class="ml-2" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>

                <template slot="filter">
                    <group-select :model="filterFormModel" :isFilter="true" @change="search"></group-select>
                </template>
                <template #cell(name)="{item}">
                    <span :class="((Date.now() - item.last_online_time * 1000) <= 60 * 15 * 1000 ? 'c-signal is-success' : 'c-signal')"><span class="h-word-break">{{item.name}}</span></span>
                    <div v-if='!clientId && item.client_id > 0 && params.clients[item.client_id]'>
                        <span class="small text-gray-999">Đơn vị: </span> 
                        <span>{{params.clients[item.client_id]}}</span> 
                    </div>
                </template>
                <template #cell(gender_id)="{item}">
                    <span v-if="item.gender_id">{{genderOptions.find(o => {return o.value === item.gender_id}).text}}</span>
                </template>
                <template #cell(role)="{item}">
                    <div>
                        <span v-if="params.roles[item.id]" >
                            <span v-for="role in params.roles[item.id]" :key="role" class="badge bg-gray ml-1" >{{params.groups[role]}}</span>
                        </span>
                    </div>
                    <div v-if="item.ref_type === 'svf' && item.ref_id && params.svfSites && params.svfSites[item.ref_id]">
                        <span class="badge bg-info ml-1">{{params.svfSites[item.ref_id]}}</span>
                    </div>
                </template>
                <template #cell(last_login_time)="{value}">
                    <i v-if="!value" class="small text-info" >Chưa đăng nhập</i>
                    <span v-if="value" >{{value * 1000 | datetimeFormat}}</span>
                </template>
                <template #cell(blocked)="{item}">
                    <i v-if="item.blocked" class="small text-danger" >Tạm khoá</i>
                    <i v-else class="small text-success" >Hoạt động</i>
                </template>
                <template #cell(action)="{item}">
                    <div class="btn-action-3" >
                        <g-button :loading="updateLoading" size="sm" variant="outline-info" class="ml-1 mb-1" icon="pencil" @click='update(item.id)' />
                        <g-button :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />

                        <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-3 mb-1" icon="key" @click='pwd(item.id)' title="Đổi mật khẩu"/>
                        <g-button v-if='!clientId' :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-sign-in-alt" @click='use(item.id)' title="Sử dụng"/>
                    </div>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <client-select :disabled="clientId" label="Đơn vị" :required='true' :model="formModel" :errors="formErrors" />
            <div class="row">
                <div class="col-md">
                    <form-input label="Họ và tên" :required='true' :model="formModel" :errors="formErrors" attribute="name" placeholder="Tên nhân viên"></form-input>
                    <form-input label="Số điện thoại" :model="formModel" :errors="formErrors" attribute="mobile"></form-input>
                    <form-select label="Giới tính" :model="formModel" attribute="gender_id" :errors="formErrors" :options="genderOptions" />
                </div>
                <div class="col-md">
                    <form-input label="Email" :required='true' :model="formModel" :errors="formErrors" attribute="email"></form-input>
                    <form-input v-if="!formModel.id" label="Mật khẩu" :required='true' :model="formModel" :errors="formErrors" attribute="password"></form-input>
                    <group-select :disabled="!formModel.client_id" label="Quyền" :client-id="formModel.client_id" :model="formModel" :errors="formErrors" :multiple="true"></group-select>
                </div>
            </div>
            <form-textarea label="Mô tả" :model="formModel" :errors="formErrors" attribute="description"></form-textarea>
            <form-checkbox chk-label="Tạm khoá tài khoản" :model="formModel" :errors="formErrors"  attribute="blocked" />
            <!-- <form-checkbox v-if="!formModel.id" chk-label="Gửi email lấy lại mật khẩu" :model="formModel" :errors="formErrors"  attribute="forgot" /> -->
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showPwd" title="Đổi mật khẩu"  @ok="savePwd" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <form-checkbox chk-label="Gửi email lấy lại mật khẩu" :model="formModel" :errors="formErrors"  attribute="forgot" />
            <form-input v-if="formModel.forgot != 1" label="Mật khẩu mới" :model="formModel" :errors="formErrors" attribute="password"></form-input>
            <div v-else class="notify-alert alert alert-info">
                Hệ thống sẽ gửi đến hòm thư <b>{{formModel.email}}</b> một email. 
                Trong nội dung có chưa đường dẫn để nhân viên <b>{{formModel.name}}</b> có thể nhập mật khẩu mới
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Xác nhận
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import GroupSelect from '@/components/StaffGroupSelect';
    import ClientSelect from '@/components/ClientSelect';

    export default {
        props: ["clientId"],
        components: {GroupSelect, ClientSelect},
        mixins: [list, crud],
        data: function () {
            return {
                service: '/staff',
                fields: [
                    {key: 'name', label: "Tên nhân viên"},
                    {key: 'email', label: "Email"},
                    {key: 'role', label: "Quyền"},
                    {key: 'last_login_time', label: "Đăng nhập lần cuối", class: 'text-center'},
                    {key: 'blocked', label: "Trạng thái", class: 'text-center'},
                    {key: 'action', label: "Hành động", class: 'text-center'},
                ],
                defaultFilterFormModel: {
                    client_id: this.clientId ? this.clientId : null,
                    keyword: '',
                    online: null,
                    blocked: 0,
                    group: null,
                },
                defaultFormModel: {
                    client_id: this.clientId ? this.clientId : null,
                    name: '',
                    email: '',
                    mobile: '',
                    blocked: 0,
                    group: null,
                    gender_id: null,
                    ref_type: null,
                    ref_id: null
                },
                blockOptions: [
                    {value: null, text: "Chọn trạng thái"},
                    {value: 1, text: "Hoạt động"},
                    {value: 0, text: "Tạm khoá"}
                ],
                genderOptions: [
                    {value: null, text: "Chọn giới tính"},
                    {value: "1", text: "Nam"},
                    {value: "2", text: "Nữ"}
                ],
                showPwd: false
            };
        },
        methods: {
            pwd(id) {
                this.updateLoading = true;
                this.$service.get(`${this.service}/get?id=${id}`).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showPwd = true;
                    response.data.forgot = 0;

                    this.formModel = response.data;
                    this.formModel.id = id;
                    this.formModel.password = null;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            savePwd(evt) {
                if (evt) {
                    evt.preventDefault();
                }
                this.saveLoading = true;
                this.$service({url: `${this.service}/pwd?id=${this.formModel.id}`, method: 'put', data: this.formModel}).then(response => {
                    this.showPwd = false;
                    if (response.data) {
                        this.$swal({
                            text: this.formModel.forgot == 0 ? 'Đổi mật khẩu thành công' : 'Yêu cầu khôi phục mật khẩu thành công, kiểm tra hòm mail của bạn và làm theo hướng dẫn, lưu ý kiểm tra cả spam và inbox!',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    } else {
                        this.$swal({
                            title: "Có lỗi",
                            text: 'Đổi mật khẩu không thành công',
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    }
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                        this.showPwd = false;
                    } else {
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
            use: function (id) {
                this.updateLoading = true;
                let url = process.env.VUE_APP_BASE_PATH + "#/use/";
                this.$service.get(`${this.service}/token?id=${id}`, {}).then(response => {
                    this.load();
                    window.open(url + response.data.token, '_blank');
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
        }
    }
</script>