<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state">
        <div class="c-smart-select" :class="focused?'active':''">
            <div class="c-smart-select__inner">
                <div class="c-smart-select__blank" @click="focus">
                    {{ placeHolder }}
                </div>
                <i class="c-smart-select__icon fa fa-user-circle text-gray"></i>
                <div class="c-smart-select__current" @click="focus">
                    <div v-if="multiple && selected && selected.length">
                        <label v-for="item in selected" :key="item.id">
                            <span>{{ item.name }}</span>
                            <i class="fa fa-times" @click="remove(item)"></i>
                        </label>
                    </div>
                    <label v-else-if="selected && !multiple">
                        <span>{{ selected.name }}</span>
                        <i class="fa fa-times" @click="clear"></i>
                    </label>
                </div>
                <div class="c-smart-select__ovelay">
                    <input ref="searchBox" class="form-control form-control-sm" v-model="filterFormModel.keyword" :placeholder="placeHolder" @keyup="search" @click="focus" @focusout="focusOut"/>
                    <div class="c-smart-select__list" :class="focused?'active':''">
                        <div class="col-12 text-center" v-if="firstLoading || listLoading">
                            <b-spinner small></b-spinner>
                        </div>
                        <div v-else-if="!itemCount && !listLoading" class="col-12 text-center text-danger text-sm">
                            <span>Không tìm thấy kết quả nào!</span>
                        </div>
                        <ul v-else>
                            <li v-for="item in items" :key="item.id" @click="select(item)">
                                <div class="c-smart-select-item is-multichoice">
                                    <div class="animated-checkbox">
                                        <label>
                                            <i v-if="(!multiple && item.id === model[attribute]) || (multiple && model[attribute] && model[attribute].includes(item.id))" class="fa fa-check-square text-gray"></i>
                                            <i v-else class="far fa-square text-gray"></i>
                                            <span class="label-text"></span>
                                        </label>
                                    </div>
                                    <div class="c-smart-select-item__row">
                                        <b>{{ item.name }}</b> 
                                        <span v-if='item.is_active === 1 || item.is_active === 0'>
                                            - 
                                            <span v-if="item.is_active == 1" class="text-success">Hoạt động</span>
                                            <span v-else class="text-danger" >Tạm khoá</span>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </b-form-group>
</template>

<script>
    import list from '../mixins/List';
    import _ from "lodash";

    export default {
        name: "staffGroupSelect",
        props: {
            model: Object,
            attribute: {
                type: String,
                default: 'group'
            },
            clientId: {
                type: String,
                default: null
            },
            label: String,
            errors: {},
            placeHolder: {
                type: String,
                default: "Chọn quyền"
            },
            multiple: Boolean,
            isFilter: Boolean
        },
        mixins: [list],
        data() {
            return {
                selected: null,
                state: null,
                errorTxt: '',
                options: [],
                showList: false,
                service: '/auth-group',
                autoLoad: false,
                defaultFilterFormModel: {
                    keyword: '',
                    status: 0,
                    client_id: this.clientId
                },
                focused: false,
                changeTrigger: true,
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (this.changeTrigger) {
                        if (val[this.attribute] !== null) {
                            this.loadSelected();
                        } else {
                            this.selected = null;
                        }
                    }
                    this.changeTrigger = true;
                }, deep: true
            },
            clientId: {
                handler(val) {
                    this.defaultFilterFormModel.client_id = val;
                    this.filterFormModel.client_id = val;
                    this.load()
                }, deep: true
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            list() {
                this.showList = true;
                this.load();
            },
            loadSelected: _.debounce(function () {
                if (this.model[this.attribute]) {
                    let ids;
                    if (this.multiple) {
                        ids = this.model[this.attribute].filter(i => i).join(',');
                    } else {
                        ids = [];
                        ids.push(this.model[this.attribute]);
                    }
                    if (ids) {
                        this.$service.get(this.service, {
                            params: {page: 1, pageSize: 100, ids: ids}
                        }).then(response => {
                            if (this.multiple) {
                                this.selected = response.data.data;
                            } else if (response.data.docs.length) {
                                this.selected = response.data.data[0];
                            }
                        });
                    }
                } else if (!this.multiple) {
                    if (this.isFilter) {
                        this.selected = {id: null, name: "Tất cả quyền"};
                    } else {
                        this.selected = {id: null, name: "Mặc định"};
                    }
                }
            }, 100),
            select(item) {
                if (this.multiple) {
                    if (!this.selected) {
                        this.selected = [];
                    }
                    if (!this.model[this.attribute]) {
                        this.model[this.attribute] = [];
                    }
                    if (!this.model[this.attribute].includes(item.id)) {
                        this.selected.push(item);
                        this.model[this.attribute].push(item.id);
                    }
                } else {
                    this.selected = item;
                    this.model[this.attribute] = item.id;
                }
                this.changeTrigger = false;
                this.focused = false;
                this.$emit('change');
            },
            clear() {
                this.selected = null;
                this.model[this.attribute] = null;
                this.changeTrigger = false;
                this.$emit('change');
            },
            remove(item) {
                if (this.multiple && this.model[this.attribute].includes(item.id)) {
                    this.model[this.attribute] = this.model[this.attribute].filter(i => i !== item.id);
                    this.selected = this.selected.filter(i => i.id !== item.id);
                    this.changeTrigger = false;
                    this.$emit('change');
                }
            },
            focus() {
                if (!this.focused) {
                    this.filterFormModel.keyword = '';
                    this.focused = true;
                    this.$refs.searchBox.focus();
                    this.load();
                }
            },
            focusOut: _.debounce(function () {
                this.focused = false;
            }, 150),
            search: _.debounce(function () {
                this.load();
            }, 200),
            apply(response) {
                this.items = [];
                if (!this.multiple) {
                    if (this.isFilter) {
                        this.items.push({id: null, name: "Tất cả quyền"});
                    } else {
                        this.items.push({id: null, name: "Mặc định"});
                    }
                }
                this.items = this.items.concat(response.data);
                this.itemCount = response.dataCount;
                this.firstItemOfPage = this.itemCount > 0 ? (this.filterFormModel.page - 1) * this.filterFormModel.pageSize + 1 : 0;
                this.lastItemOfPage = (this.filterFormModel.page - 1) * this.filterFormModel.pageSize + this.items.length;
            }
        },
        mounted() {
            this.selected = null;
            this.loadSelected();
        }
    }
</script>